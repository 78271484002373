<template>
  <div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">
    <div class="flex h-full w-full">
      <div class="flex sm:flex-row w-full flex-col h-full">
        <EpreuveTypes @selectType="typeEpreuveChanged"/>

        <template v-if="!selectedEpreuveType">

          <div class="flex flex-col w-full sm:ml-6 sm:mt-0  ">
            <!-- Title-->
            <div class="inline-flex justify-between items-center">
              <div class="mb-2">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des examens</p>
              </div>
              <div></div>
            </div>
            <!-- Title-->

            <div class="border rounded-lg h-full">


              <div class="flex justify-center items-center h-full ">
                <el-result
                    icon="info"
                    :title="'Aucun module sélectionné'"
                    :subTitle="'Veuillez sélectionner un module'"
                >
                </el-result>
              </div>
            </div>
          </div>
        </template>

        <EpreuveList :selectedEpreuveType="selectedEpreuveType" ref="epreuveList"/>
      </div>
    </div>
  </div>
</template>

<script>
import EpreuveTypes from "./components/EpreuveTypes.vue";
import EpreuveList from "./components/EpreuveList.vue";

export default {
  components: {
    EpreuveList,
    EpreuveTypes
  },
  data() {
    return {
      currentRoute: window.location.pathname,

      selectedEpreuveType: null,

      form: {}
    }
  },
  methods: {
    typeEpreuveChanged(key) {
      this.selectedEpreuveType = key;
      this.$refs.epreuveList.loadExams(key);
    }
  },

  props: {
    linkPath: Function,
  },

  mounted() {
    this.linkPath(this.currentRoute)
  },
}
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}


</style>